





























































































































































































































































import { Component, Prop } from "vue-property-decorator";
import Layout from "@/components/designer/sidebar/layout/Layout.vue";
import { mixins } from "vue-class-component";
import { CanopyStore, LayoutStore, ShapeStore } from "@/mixins/store";
import S1SVG from "../../../../assets/images/S1.svg?inline";
import S1DVSVG from "../../../../assets/images/S1DV.svg?inline";
import D1SVG from "../../../../assets/images/D1.svg?inline";
import D2SVG from "../../../../assets/images/D2.svg?inline";
import D3SVG from "../../../../assets/images/D3.svg?inline";
import D4SVG from "../../../../assets/images/D4.svg?inline";
import D5SVG from "../../../../assets/images/D5.svg?inline";
import { LayoutRules } from "@/mixins/business_logic/layout/layout_rules";
import { Preset } from "@/models/configurator/configurator_options";
import { FabricRules } from "@/mixins/business_logic/global/fabric_rules";
import NoStripes from "@/components/designer/reusable/modal/NoStripes.vue";
import * as option from "@/models/configurator/configurator_options";
import { EventBus } from "@/event-bus";
import { ProductLineName } from "@/models/products/collection";
import { StockMixin } from "@/mixins/stock";
import { UmbrellaModel, UmbrellaType } from "@/models/products/umbrella";
@Component({
  components: {
    Layout,
    D1SVG,
    D2SVG,
    D3SVG,
    D4SVG,
    D5SVG,
    S1SVG,
    S1DVSVG,
    NoStripes,
  },
})
export default class LayoutSidebar extends mixins(
  LayoutStore,
  CanopyStore,
  ShapeStore,
  LayoutRules,
  StockMixin,
  FabricRules
) {
  @Prop({ default: false }) customizable!: boolean;
  @Prop() modalId!: string;
  @Prop({ default: true }) hasVent!: boolean;
  @Prop({ default: true }) hasValance!: boolean;
  protected dialog = false;
  protected model: UmbrellaModel | null = null;
  protected Preset = Preset;
  protected ProductLineName = ProductLineName;
  protected disableDesignerLayouts = [
    ProductLineName.Avalon,
    ProductLineName.Catalina,
    ProductLineName.Laurel,
    ProductLineName.EmeraldCoast,
  ];
  protected disableDoubleVent = [
    ProductLineName.Avalon,
    ProductLineName.Catalina,
    ProductLineName.Laurel,
    ProductLineName.Nova,
  ];
  protected umbrellaModelGroupOne = ["845", "454", "854", "864", "464"];
  protected umbrellaModelGroupTwo = ["876FMA", "ECU"];

  mounted() {
    EventBus.$on("toggleStripesModal", () => this.showStripesWarning());
    this.readStoreData();
  }
  beforeDestroy() {
    EventBus.$off("toggleStripesModal");
  }

  get isMarketType(): boolean {
    if (this.collection.umbrella_type === UmbrellaType.Market) {
      return true;
    }
    return false;
  }

  protected showStripesWarning(): void {
    this.dialog = true;
  }

  protected showDoubleVentLayouts(handle: string): boolean {
    if (this.disableDoubleVent.includes(handle as ProductLineName)) {
      return true;
    } else if (this.hasVent) {
      return false;
    } else {
      return true;
    }
  }

  protected showDesignerLayouts(handle: string): boolean {
    return this.disableDesignerLayouts.includes(handle as ProductLineName);
  }

  protected readStoreData(): void {
    if (!this.preset) {
      this.addPreset(Preset.S1);
    }
    if (!this.umbrellaModel) {
      this.$router.push("/");
    } else {
      this.model = this.umbrellaModel;
    }
  }

  protected async select(layout: Preset): Promise<void> {
    this.changeLayout(layout);
    // if (
    //   this.$store.getters["global/getRole"] !== "admin" &&
    //   (layout === option.Preset.D1 ||
    //     layout === option.Preset.D2 ||
    //     layout === option.Preset.D3 ||
    //     layout === option.Preset.D4 ||
    //     layout === option.Preset.D5)
    // ) {
    //   // if not admin role & selected designer layout
    //   const stripesFound = await this.checkModelForStripes();
    //   if (!stripesFound) {
    //     // this.changeModel(model);
    //     this.changeLayout(layout);
    //   } else {
    //     //this.selected = this.umbrellaModel; // resetting selection in ui to previous model
    //     await this.toggleStripeWarning();
    //   }
    // } else {
    //   this.changeLayout(layout);
    // }
  }

  protected async changeLayout(layout: Preset): Promise<void> {
    // prevent sending layout change when no change happens; this bugs the viewer sometimes
    if (this.preset === layout) return;

    localStorage.setItem(
      `shadeDesigner-${this.preset}`,
      JSON.stringify(this.allCanopyData)
    );
    await this.addPreset(layout);
    await this.$viewer.ChangeCanopyLayout(layout);
    await this.applyLayoutRules(true);
  }
}
